import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem, wordBreak } from './../../core';

const StyledCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-radius: ${({ theme }) => getRem(theme.size.borderRadius.large.value)};
  box-shadow: ${({ theme }) => theme.elevation[1].value};
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  min-width: 0;
  ${wordBreak}
  ${({ draggable, theme }) =>
    draggable &&
    css`
      cursor: move;

      &:active {
        box-shadow: ${theme.elevation[4].value};
      }
    `};
`;

const CardWrapper = ({ customTag, dataTestId, draggable, ...other }) => (
  <StyledCardWrapper
    data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}
    as={customTag}
    draggable={draggable}
    {...other}
  />
);

CardWrapper.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
  /** If true, makes component draggable */
  draggable: PropTypes.bool,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

CardWrapper.defaultProps = {
  customTag: undefined,
  draggable: false,
  dataTestId: '',
};

export { CardWrapper };
