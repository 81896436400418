import { css } from 'styled-components';

import { transitionAnimation } from '../animations';
import { getHexToRgb } from '../hexToRgb';

export const focusOutlineColors = {
  getDark: (theme, opacity = 0.5) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, ${opacity})`,
  getLight: (theme, opacity = 0.5) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, ${opacity})`,
  getPrimary: (theme, opacity = 0.5) => `rgba(${getHexToRgb(theme.color.primary[500].value)}, ${opacity})`,
  getSystemNegative: (theme, opacity = 0.5) =>
    `rgba(${getHexToRgb(theme.color.system.negative[500].value)}, ${opacity})`,
};

export const focusOutlinesStyles = {
  dark: css`
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};
    ${transitionAnimation('box-shadow')}
  `,
  light: css`
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getLight(theme)};
    ${transitionAnimation('box-shadow')}
  `,
  primary: css`
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getPrimary(theme)};
    ${transitionAnimation('box-shadow')}
  `,
};
