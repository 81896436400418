// This is a generated file. DO NOT modify directly.
/* eslint-disable quotes */

import { createIconWithPath } from "./createIconWithPath";
import {
  AccountCircle,
  Accounting,
  Add,
  AddAlert,
  AddCircle,
  AddLocation,
  Admin,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Alignment,
  Apps,
  ArrowBack,
  ArrowCascadeLeft,
  ArrowCascadeRight,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowUpward,
  Assignment,
  AttachFile,
  BarChart,
  BodyStyleSedan,
  Bookmark,
  BookmarkBorder,
  BubbleChart,
  BuildingBodyShop,
  BuildingCorporateOffice,
  BuildingDealer,
  BuildingMakeReady,
  BuildingNetworkColo,
  BuildingPartsWarehouse,
  BuildingPreownedBuilding,
  BuildingQuickLane,
  BuildingServiceShop,
  Campaigns,
  Cancel,
  CancelOutlined,
  CashRegister,
  CertifiedPreowned,
  Chat,
  Check,
  CheckBoxChecked,
  CheckBoxIndeterminate,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Close,
  Comment,
  CommentAdd,
  Concierge,
  ConciergePremium,
  ContactSupport,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Conversation,
  CreditCard,
  Crop,
  Customer,
  CustomerDeclined,
  CustomerReplied,
  CustomerWaiting,
  Customers,
  Dashboard,
  DatavisDown,
  DatavisDownFilled,
  DatavisDownOutline,
  DatavisUp,
  DatavisUpFilled,
  DatavisUpOutline,
  DateRange,
  Dealer,
  DealerBarcodeLabelPrinter,
  DealerBarcodeReader,
  DealerBwPrinter,
  DealerColorPrinter,
  DealerColorPrinter2,
  DealerColorPrinter3,
  DealerComputer,
  DealerDocumentScanner,
  DealerImpactPrinter,
  DealerMagneticInkPrinter,
  DealerPay,
  DealerPhone,
  DealerShowroom,
  DealerStaff,
  Declined,
  Delete,
  DeliveryRide,
  Demo,
  Description,
  DeviceLog,
  Drafts,
  DragHandle,
  DropOff,
  DropOffEarly,
  DropOffLate,
  Edit,
  EnFlag,
  Engine,
  Error,
  ErrorOutlined,
  EsFlag,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  ExpandLess,
  ExpandMore,
  Express,
  Face,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FileUpload,
  FilterList,
  Flag,
  Folder,
  FolderCreateNew,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FrCaFlag,
  FrFlag,
  Fullscreen,
  FullscreenExit,
  Help,
  Home,
  Info,
  Label,
  LabelOutline,
  LicensePlate,
  Link,
  List,
  LocationOff,
  LocationOn,
  LockOpen,
  LockOutline,
  Logo,
  LogoDarkBg,
  Logout,
  Mail,
  Menu,
  Message,
  MessageExpired,
  MobileAndroid,
  MobileIphone,
  MoreHoriz,
  MoreVert,
  MoveToInbox,
  NetworkingCdkDmsServer,
  NetworkingCloset,
  NetworkingCloudService,
  NetworkingCoverageArea,
  NetworkingRoom,
  NetworkingRouter,
  NetworkingSdwan,
  NetworkingSecurity,
  NetworkingServerFirewall,
  NetworkingServerRacks,
  NetworkingSwitches,
  NetworkingWireless,
  NextAppointment,
  NoteAdd,
  Notification,
  NotificationActive,
  NotificationOff,
  NotificationPaused,
  OEMCommunications,
  Odometer,
  OpenInBrowser,
  OpenInNew,
  Order,
  OtherReports,
  PageFirst,
  PageLast,
  Parts,
  PartsInStock,
  PartsOrdered,
  PartsOutOfStock,
  Payroll,
  People,
  Person,
  PersonAdd,
  Phone,
  PhoneDisabled,
  Photo,
  PieChart,
  Print,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Redo,
  Refresh,
  RemoteService,
  Remove,
  Rental,
  Reorder,
  RepairOrder,
  Reply,
  ReplyAll,
  Restore,
  Sales,
  Save,
  Schedule,
  Scheduling,
  Search,
  Send,
  SendEmail,
  ServiceLift,
  Settings,
  Share,
  SmsCustomerReplied,
  Snooze,
  Sort,
  Star,
  StarBorder,
  StarHalf,
  StatusDefault,
  StatusInformation,
  StatusInformationI,
  StatusInformationTrendDown,
  StatusInformationTrendUp,
  StatusNegative,
  StatusNegativeTrendDown,
  StatusNegativeTrendUp,
  StatusNegativeX,
  StatusPositive,
  StatusPositiveSuccess,
  StatusPositiveTrendDown,
  StatusPositiveTrendUp,
  StatusWarning,
  StatusWarningExclaimationOutlineRound,
  StatusWarningExclamation,
  StatusWarningTrendDown,
  StatusWarningTrendUp,
  StockIn,
  StockOut,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Sync,
  TableSettings,
  TextSent,
  TextSms,
  ThreatLog,
  ThumbDown,
  ThumbUp,
  Timeline,
  Tire,
  Today,
  TradeIn,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  Undo,
  UnfoldLess,
  UnfoldMore,
  User,
  UserAdd,
  Users,
  Valet,
  VehicleBus,
  VehicleCar,
  VehicleLeaving,
  VehicleLoaner,
  VehicleShuttle,
  ViewCard,
  ViewGrid,
  ViewList,
  Visibility,
  VisibilityOff,
  VoidRequest,
  Waiting,
  WaitingOnCustomerResponse,
  WaitingParts,
  WaitingWarranty,
  Warranty,
  WeightScale,
  ZoomIn,
  ZoomOut,
} from "./svgPaths";
export const IconAccountCircle = /* #__PURE__ */ createIconWithPath(
  AccountCircle
);
export const IconAccounting = /* #__PURE__ */ createIconWithPath(Accounting);
export const IconAdd = /* #__PURE__ */ createIconWithPath(Add);
export const IconAddAlert = /* #__PURE__ */ createIconWithPath(AddAlert);
export const IconAddCircle = /* #__PURE__ */ createIconWithPath(AddCircle);
export const IconAddLocation = /* #__PURE__ */ createIconWithPath(AddLocation);
export const IconAdmin = /* #__PURE__ */ createIconWithPath(Admin);
export const IconAlarm = /* #__PURE__ */ createIconWithPath(Alarm);
export const IconAlarmAdd = /* #__PURE__ */ createIconWithPath(AlarmAdd);
export const IconAlarmOff = /* #__PURE__ */ createIconWithPath(AlarmOff);
export const IconAlarmOn = /* #__PURE__ */ createIconWithPath(AlarmOn);
export const IconAlignment = /* #__PURE__ */ createIconWithPath(Alignment);
export const IconApps = /* #__PURE__ */ createIconWithPath(Apps);
export const IconArrowBack = /* #__PURE__ */ createIconWithPath(ArrowBack);
export const IconArrowCascadeLeft = /* #__PURE__ */ createIconWithPath(
  ArrowCascadeLeft
);
export const IconArrowCascadeRight = /* #__PURE__ */ createIconWithPath(
  ArrowCascadeRight
);
export const IconArrowDownward = /* #__PURE__ */ createIconWithPath(
  ArrowDownward
);
export const IconArrowDropDown = /* #__PURE__ */ createIconWithPath(
  ArrowDropDown
);
export const IconArrowDropUp = /* #__PURE__ */ createIconWithPath(ArrowDropUp);
export const IconArrowForward = /* #__PURE__ */ createIconWithPath(
  ArrowForward
);
export const IconArrowUpward = /* #__PURE__ */ createIconWithPath(ArrowUpward);
export const IconAssignment = /* #__PURE__ */ createIconWithPath(Assignment);
export const IconAttachFile = /* #__PURE__ */ createIconWithPath(AttachFile);
export const IconBarChart = /* #__PURE__ */ createIconWithPath(BarChart);
export const IconBodyStyleSedan = /* #__PURE__ */ createIconWithPath(
  BodyStyleSedan
);
export const IconBookmark = /* #__PURE__ */ createIconWithPath(Bookmark);
export const IconBookmarkBorder = /* #__PURE__ */ createIconWithPath(
  BookmarkBorder
);
export const IconBubbleChart = /* #__PURE__ */ createIconWithPath(BubbleChart);
export const IconBuildingBodyShop = /* #__PURE__ */ createIconWithPath(
  BuildingBodyShop
);
export const IconBuildingCorporateOffice = /* #__PURE__ */ createIconWithPath(
  BuildingCorporateOffice
);
export const IconBuildingDealer = /* #__PURE__ */ createIconWithPath(
  BuildingDealer
);
export const IconBuildingMakeReady = /* #__PURE__ */ createIconWithPath(
  BuildingMakeReady
);
export const IconBuildingNetworkColo = /* #__PURE__ */ createIconWithPath(
  BuildingNetworkColo
);
export const IconBuildingPartsWarehouse = /* #__PURE__ */ createIconWithPath(
  BuildingPartsWarehouse
);
export const IconBuildingPreownedBuilding = /* #__PURE__ */ createIconWithPath(
  BuildingPreownedBuilding
);
export const IconBuildingQuickLane = /* #__PURE__ */ createIconWithPath(
  BuildingQuickLane
);
export const IconBuildingServiceShop = /* #__PURE__ */ createIconWithPath(
  BuildingServiceShop
);
export const IconCampaigns = /* #__PURE__ */ createIconWithPath(Campaigns);
export const IconCancel = /* #__PURE__ */ createIconWithPath(Cancel);
export const IconCancelOutlined = /* #__PURE__ */ createIconWithPath(
  CancelOutlined
);
export const IconCashRegister = /* #__PURE__ */ createIconWithPath(
  CashRegister
);
export const IconCertifiedPreowned = /* #__PURE__ */ createIconWithPath(
  CertifiedPreowned
);
export const IconChat = /* #__PURE__ */ createIconWithPath(Chat);
export const IconCheck = /* #__PURE__ */ createIconWithPath(Check);
export const IconCheckBoxChecked = /* #__PURE__ */ createIconWithPath(
  CheckBoxChecked
);
export const IconCheckBoxIndeterminate = /* #__PURE__ */ createIconWithPath(
  CheckBoxIndeterminate
);
export const IconCheckBoxOutlineBlank = /* #__PURE__ */ createIconWithPath(
  CheckBoxOutlineBlank
);
export const IconCheckCircle = /* #__PURE__ */ createIconWithPath(CheckCircle);
export const IconChevronLeft = /* #__PURE__ */ createIconWithPath(ChevronLeft);
export const IconChevronRight = /* #__PURE__ */ createIconWithPath(
  ChevronRight
);
export const IconClose = /* #__PURE__ */ createIconWithPath(Close);
export const IconComment = /* #__PURE__ */ createIconWithPath(Comment);
export const IconCommentAdd = /* #__PURE__ */ createIconWithPath(CommentAdd);
export const IconConcierge = /* #__PURE__ */ createIconWithPath(Concierge);
export const IconConciergePremium = /* #__PURE__ */ createIconWithPath(
  ConciergePremium
);
export const IconContactSupport = /* #__PURE__ */ createIconWithPath(
  ContactSupport
);
export const IconContentCopy = /* #__PURE__ */ createIconWithPath(ContentCopy);
export const IconContentCut = /* #__PURE__ */ createIconWithPath(ContentCut);
export const IconContentPaste = /* #__PURE__ */ createIconWithPath(
  ContentPaste
);
export const IconConversation = /* #__PURE__ */ createIconWithPath(
  Conversation
);
export const IconCreditCard = /* #__PURE__ */ createIconWithPath(CreditCard);
export const IconCrop = /* #__PURE__ */ createIconWithPath(Crop);
export const IconCustomer = /* #__PURE__ */ createIconWithPath(Customer);
export const IconCustomerDeclined = /* #__PURE__ */ createIconWithPath(
  CustomerDeclined
);
export const IconCustomerReplied = /* #__PURE__ */ createIconWithPath(
  CustomerReplied
);
export const IconCustomerWaiting = /* #__PURE__ */ createIconWithPath(
  CustomerWaiting
);
export const IconCustomers = /* #__PURE__ */ createIconWithPath(Customers);
export const IconDashboard = /* #__PURE__ */ createIconWithPath(Dashboard);
export const IconDatavisDown = /* #__PURE__ */ createIconWithPath(DatavisDown);
export const IconDatavisDownFilled = /* #__PURE__ */ createIconWithPath(
  DatavisDownFilled
);
export const IconDatavisDownOutline = /* #__PURE__ */ createIconWithPath(
  DatavisDownOutline
);
export const IconDatavisUp = /* #__PURE__ */ createIconWithPath(DatavisUp);
export const IconDatavisUpFilled = /* #__PURE__ */ createIconWithPath(
  DatavisUpFilled
);
export const IconDatavisUpOutline = /* #__PURE__ */ createIconWithPath(
  DatavisUpOutline
);
export const IconDateRange = /* #__PURE__ */ createIconWithPath(DateRange);
export const IconDealer = /* #__PURE__ */ createIconWithPath(Dealer);
export const IconDealerBarcodeLabelPrinter = /* #__PURE__ */ createIconWithPath(
  DealerBarcodeLabelPrinter
);
export const IconDealerBarcodeReader = /* #__PURE__ */ createIconWithPath(
  DealerBarcodeReader
);
export const IconDealerBwPrinter = /* #__PURE__ */ createIconWithPath(
  DealerBwPrinter
);
export const IconDealerColorPrinter = /* #__PURE__ */ createIconWithPath(
  DealerColorPrinter
);
export const IconDealerColorPrinter2 = /* #__PURE__ */ createIconWithPath(
  DealerColorPrinter2
);
export const IconDealerColorPrinter3 = /* #__PURE__ */ createIconWithPath(
  DealerColorPrinter3
);
export const IconDealerComputer = /* #__PURE__ */ createIconWithPath(
  DealerComputer
);
export const IconDealerDocumentScanner = /* #__PURE__ */ createIconWithPath(
  DealerDocumentScanner
);
export const IconDealerImpactPrinter = /* #__PURE__ */ createIconWithPath(
  DealerImpactPrinter
);
export const IconDealerMagneticInkPrinter = /* #__PURE__ */ createIconWithPath(
  DealerMagneticInkPrinter
);
export const IconDealerPay = /* #__PURE__ */ createIconWithPath(DealerPay);
export const IconDealerPhone = /* #__PURE__ */ createIconWithPath(DealerPhone);
export const IconDealerShowroom = /* #__PURE__ */ createIconWithPath(
  DealerShowroom
);
export const IconDealerStaff = /* #__PURE__ */ createIconWithPath(DealerStaff);
export const IconDeclined = /* #__PURE__ */ createIconWithPath(Declined);
export const IconDelete = /* #__PURE__ */ createIconWithPath(Delete);
export const IconDeliveryRide = /* #__PURE__ */ createIconWithPath(
  DeliveryRide
);
export const IconDemo = /* #__PURE__ */ createIconWithPath(Demo);
export const IconDescription = /* #__PURE__ */ createIconWithPath(Description);
export const IconDeviceLog = /* #__PURE__ */ createIconWithPath(DeviceLog);
export const IconDrafts = /* #__PURE__ */ createIconWithPath(Drafts);
export const IconDragHandle = /* #__PURE__ */ createIconWithPath(DragHandle);
export const IconDropOff = /* #__PURE__ */ createIconWithPath(DropOff);
export const IconDropOffEarly = /* #__PURE__ */ createIconWithPath(
  DropOffEarly
);
export const IconDropOffLate = /* #__PURE__ */ createIconWithPath(DropOffLate);
export const IconEdit = /* #__PURE__ */ createIconWithPath(Edit);
export const IconEnFlag = /* #__PURE__ */ createIconWithPath(EnFlag);
export const IconEngine = /* #__PURE__ */ createIconWithPath(Engine);
export const IconError = /* #__PURE__ */ createIconWithPath(Error);
export const IconErrorOutlined = /* #__PURE__ */ createIconWithPath(
  ErrorOutlined
);
export const IconEsFlag = /* #__PURE__ */ createIconWithPath(EsFlag);
export const IconEvent = /* #__PURE__ */ createIconWithPath(Event);
export const IconEventAvailable = /* #__PURE__ */ createIconWithPath(
  EventAvailable
);
export const IconEventBusy = /* #__PURE__ */ createIconWithPath(EventBusy);
export const IconEventNote = /* #__PURE__ */ createIconWithPath(EventNote);
export const IconExpandLess = /* #__PURE__ */ createIconWithPath(ExpandLess);
export const IconExpandMore = /* #__PURE__ */ createIconWithPath(ExpandMore);
export const IconExpress = /* #__PURE__ */ createIconWithPath(Express);
export const IconFace = /* #__PURE__ */ createIconWithPath(Face);
export const IconFavorite = /* #__PURE__ */ createIconWithPath(Favorite);
export const IconFavoriteBorder = /* #__PURE__ */ createIconWithPath(
  FavoriteBorder
);
export const IconFileDownload = /* #__PURE__ */ createIconWithPath(
  FileDownload
);
export const IconFileUpload = /* #__PURE__ */ createIconWithPath(FileUpload);
export const IconFilterList = /* #__PURE__ */ createIconWithPath(FilterList);
export const IconFlag = /* #__PURE__ */ createIconWithPath(Flag);
export const IconFolder = /* #__PURE__ */ createIconWithPath(Folder);
export const IconFolderCreateNew = /* #__PURE__ */ createIconWithPath(
  FolderCreateNew
);
export const IconFolderOpen = /* #__PURE__ */ createIconWithPath(FolderOpen);
export const IconFolderShared = /* #__PURE__ */ createIconWithPath(
  FolderShared
);
export const IconFolderSpecial = /* #__PURE__ */ createIconWithPath(
  FolderSpecial
);
export const IconFrCaFlag = /* #__PURE__ */ createIconWithPath(FrCaFlag);
export const IconFrFlag = /* #__PURE__ */ createIconWithPath(FrFlag);
export const IconFullscreen = /* #__PURE__ */ createIconWithPath(Fullscreen);
export const IconFullscreenExit = /* #__PURE__ */ createIconWithPath(
  FullscreenExit
);
export const IconHelp = /* #__PURE__ */ createIconWithPath(Help);
export const IconHome = /* #__PURE__ */ createIconWithPath(Home);
export const IconInfo = /* #__PURE__ */ createIconWithPath(Info);
export const IconLabel = /* #__PURE__ */ createIconWithPath(Label);
export const IconLabelOutline = /* #__PURE__ */ createIconWithPath(
  LabelOutline
);
export const IconLicensePlate = /* #__PURE__ */ createIconWithPath(
  LicensePlate
);
export const IconLink = /* #__PURE__ */ createIconWithPath(Link);
export const IconList = /* #__PURE__ */ createIconWithPath(List);
export const IconLocationOff = /* #__PURE__ */ createIconWithPath(LocationOff);
export const IconLocationOn = /* #__PURE__ */ createIconWithPath(LocationOn);
export const IconLockOpen = /* #__PURE__ */ createIconWithPath(LockOpen);
export const IconLockOutline = /* #__PURE__ */ createIconWithPath(LockOutline);
export const IconLogo = /* #__PURE__ */ createIconWithPath(Logo);
export const IconLogoDarkBg = /* #__PURE__ */ createIconWithPath(LogoDarkBg);
export const IconLogout = /* #__PURE__ */ createIconWithPath(Logout);
export const IconMail = /* #__PURE__ */ createIconWithPath(Mail);
export const IconMenu = /* #__PURE__ */ createIconWithPath(Menu);
export const IconMessage = /* #__PURE__ */ createIconWithPath(Message);
export const IconMessageExpired = /* #__PURE__ */ createIconWithPath(
  MessageExpired
);
export const IconMobileAndroid = /* #__PURE__ */ createIconWithPath(
  MobileAndroid
);
export const IconMobileIphone = /* #__PURE__ */ createIconWithPath(
  MobileIphone
);
export const IconMoreHoriz = /* #__PURE__ */ createIconWithPath(MoreHoriz);
export const IconMoreVert = /* #__PURE__ */ createIconWithPath(MoreVert);
export const IconMoveToInbox = /* #__PURE__ */ createIconWithPath(MoveToInbox);
export const IconNetworkingCdkDmsServer = /* #__PURE__ */ createIconWithPath(
  NetworkingCdkDmsServer
);
export const IconNetworkingCloset = /* #__PURE__ */ createIconWithPath(
  NetworkingCloset
);
export const IconNetworkingCloudService = /* #__PURE__ */ createIconWithPath(
  NetworkingCloudService
);
export const IconNetworkingCoverageArea = /* #__PURE__ */ createIconWithPath(
  NetworkingCoverageArea
);
export const IconNetworkingRoom = /* #__PURE__ */ createIconWithPath(
  NetworkingRoom
);
export const IconNetworkingRouter = /* #__PURE__ */ createIconWithPath(
  NetworkingRouter
);
export const IconNetworkingSdwan = /* #__PURE__ */ createIconWithPath(
  NetworkingSdwan
);
export const IconNetworkingSecurity = /* #__PURE__ */ createIconWithPath(
  NetworkingSecurity
);
export const IconNetworkingServerFirewall = /* #__PURE__ */ createIconWithPath(
  NetworkingServerFirewall
);
export const IconNetworkingServerRacks = /* #__PURE__ */ createIconWithPath(
  NetworkingServerRacks
);
export const IconNetworkingSwitches = /* #__PURE__ */ createIconWithPath(
  NetworkingSwitches
);
export const IconNetworkingWireless = /* #__PURE__ */ createIconWithPath(
  NetworkingWireless
);
export const IconNextAppointment = /* #__PURE__ */ createIconWithPath(
  NextAppointment
);
export const IconNoteAdd = /* #__PURE__ */ createIconWithPath(NoteAdd);
export const IconNotification = /* #__PURE__ */ createIconWithPath(
  Notification
);
export const IconNotificationActive = /* #__PURE__ */ createIconWithPath(
  NotificationActive
);
export const IconNotificationOff = /* #__PURE__ */ createIconWithPath(
  NotificationOff
);
export const IconNotificationPaused = /* #__PURE__ */ createIconWithPath(
  NotificationPaused
);
export const IconOEMCommunications = /* #__PURE__ */ createIconWithPath(
  OEMCommunications
);
export const IconOdometer = /* #__PURE__ */ createIconWithPath(Odometer);
export const IconOpenInBrowser = /* #__PURE__ */ createIconWithPath(
  OpenInBrowser
);
export const IconOpenInNew = /* #__PURE__ */ createIconWithPath(OpenInNew);
export const IconOrder = /* #__PURE__ */ createIconWithPath(Order);
export const IconOtherReports = /* #__PURE__ */ createIconWithPath(
  OtherReports
);
export const IconPageFirst = /* #__PURE__ */ createIconWithPath(PageFirst);
export const IconPageLast = /* #__PURE__ */ createIconWithPath(PageLast);
export const IconParts = /* #__PURE__ */ createIconWithPath(Parts);
export const IconPartsInStock = /* #__PURE__ */ createIconWithPath(
  PartsInStock
);
export const IconPartsOrdered = /* #__PURE__ */ createIconWithPath(
  PartsOrdered
);
export const IconPartsOutOfStock = /* #__PURE__ */ createIconWithPath(
  PartsOutOfStock
);
export const IconPayroll = /* #__PURE__ */ createIconWithPath(Payroll);
export const IconPeople = /* #__PURE__ */ createIconWithPath(People);
export const IconPerson = /* #__PURE__ */ createIconWithPath(Person);
export const IconPersonAdd = /* #__PURE__ */ createIconWithPath(PersonAdd);
export const IconPhone = /* #__PURE__ */ createIconWithPath(Phone);
export const IconPhoneDisabled = /* #__PURE__ */ createIconWithPath(
  PhoneDisabled
);
export const IconPhoto = /* #__PURE__ */ createIconWithPath(Photo);
export const IconPieChart = /* #__PURE__ */ createIconWithPath(PieChart);
export const IconPrint = /* #__PURE__ */ createIconWithPath(Print);
export const IconRadioButtonChecked = /* #__PURE__ */ createIconWithPath(
  RadioButtonChecked
);
export const IconRadioButtonUnchecked = /* #__PURE__ */ createIconWithPath(
  RadioButtonUnchecked
);
export const IconRedo = /* #__PURE__ */ createIconWithPath(Redo);
export const IconRefresh = /* #__PURE__ */ createIconWithPath(Refresh);
export const IconRemoteService = /* #__PURE__ */ createIconWithPath(
  RemoteService
);
export const IconRemove = /* #__PURE__ */ createIconWithPath(Remove);
export const IconRental = /* #__PURE__ */ createIconWithPath(Rental);
export const IconReorder = /* #__PURE__ */ createIconWithPath(Reorder);
export const IconRepairOrder = /* #__PURE__ */ createIconWithPath(RepairOrder);
export const IconReply = /* #__PURE__ */ createIconWithPath(Reply);
export const IconReplyAll = /* #__PURE__ */ createIconWithPath(ReplyAll);
export const IconRestore = /* #__PURE__ */ createIconWithPath(Restore);
export const IconSales = /* #__PURE__ */ createIconWithPath(Sales);
export const IconSave = /* #__PURE__ */ createIconWithPath(Save);
export const IconSchedule = /* #__PURE__ */ createIconWithPath(Schedule);
export const IconScheduling = /* #__PURE__ */ createIconWithPath(Scheduling);
export const IconSearch = /* #__PURE__ */ createIconWithPath(Search);
export const IconSend = /* #__PURE__ */ createIconWithPath(Send);
export const IconSendEmail = /* #__PURE__ */ createIconWithPath(SendEmail);
export const IconServiceLift = /* #__PURE__ */ createIconWithPath(ServiceLift);
export const IconSettings = /* #__PURE__ */ createIconWithPath(Settings);
export const IconShare = /* #__PURE__ */ createIconWithPath(Share);
export const IconSmsCustomerReplied = /* #__PURE__ */ createIconWithPath(
  SmsCustomerReplied
);
export const IconSnooze = /* #__PURE__ */ createIconWithPath(Snooze);
export const IconSort = /* #__PURE__ */ createIconWithPath(Sort);
export const IconStar = /* #__PURE__ */ createIconWithPath(Star);
export const IconStarBorder = /* #__PURE__ */ createIconWithPath(StarBorder);
export const IconStarHalf = /* #__PURE__ */ createIconWithPath(StarHalf);
export const IconStatusDefault = /* #__PURE__ */ createIconWithPath(
  StatusDefault
);
export const IconStatusInformation = /* #__PURE__ */ createIconWithPath(
  StatusInformation
);
export const IconStatusInformationI = /* #__PURE__ */ createIconWithPath(
  StatusInformationI
);
export const IconStatusInformationTrendDown = /* #__PURE__ */ createIconWithPath(
  StatusInformationTrendDown
);
export const IconStatusInformationTrendUp = /* #__PURE__ */ createIconWithPath(
  StatusInformationTrendUp
);
export const IconStatusNegative = /* #__PURE__ */ createIconWithPath(
  StatusNegative
);
export const IconStatusNegativeTrendDown = /* #__PURE__ */ createIconWithPath(
  StatusNegativeTrendDown
);
export const IconStatusNegativeTrendUp = /* #__PURE__ */ createIconWithPath(
  StatusNegativeTrendUp
);
export const IconStatusNegativeX = /* #__PURE__ */ createIconWithPath(
  StatusNegativeX
);
export const IconStatusPositive = /* #__PURE__ */ createIconWithPath(
  StatusPositive
);
export const IconStatusPositiveSuccess = /* #__PURE__ */ createIconWithPath(
  StatusPositiveSuccess
);
export const IconStatusPositiveTrendDown = /* #__PURE__ */ createIconWithPath(
  StatusPositiveTrendDown
);
export const IconStatusPositiveTrendUp = /* #__PURE__ */ createIconWithPath(
  StatusPositiveTrendUp
);
export const IconStatusWarning = /* #__PURE__ */ createIconWithPath(
  StatusWarning
);
export const IconStatusWarningExclaimationOutlineRound = /* #__PURE__ */ createIconWithPath(
  StatusWarningExclaimationOutlineRound
);
export const IconStatusWarningExclamation = /* #__PURE__ */ createIconWithPath(
  StatusWarningExclamation
);
export const IconStatusWarningTrendDown = /* #__PURE__ */ createIconWithPath(
  StatusWarningTrendDown
);
export const IconStatusWarningTrendUp = /* #__PURE__ */ createIconWithPath(
  StatusWarningTrendUp
);
export const IconStockIn = /* #__PURE__ */ createIconWithPath(StockIn);
export const IconStockOut = /* #__PURE__ */ createIconWithPath(StockOut);
export const IconSubdirectoryArrowLeft = /* #__PURE__ */ createIconWithPath(
  SubdirectoryArrowLeft
);
export const IconSubdirectoryArrowRight = /* #__PURE__ */ createIconWithPath(
  SubdirectoryArrowRight
);
export const IconSync = /* #__PURE__ */ createIconWithPath(Sync);
export const IconTableSettings = /* #__PURE__ */ createIconWithPath(
  TableSettings
);
export const IconTextSent = /* #__PURE__ */ createIconWithPath(TextSent);
export const IconTextSms = /* #__PURE__ */ createIconWithPath(TextSms);
export const IconThreatLog = /* #__PURE__ */ createIconWithPath(ThreatLog);
export const IconThumbDown = /* #__PURE__ */ createIconWithPath(ThumbDown);
export const IconThumbUp = /* #__PURE__ */ createIconWithPath(ThumbUp);
export const IconTimeline = /* #__PURE__ */ createIconWithPath(Timeline);
export const IconTire = /* #__PURE__ */ createIconWithPath(Tire);
export const IconToday = /* #__PURE__ */ createIconWithPath(Today);
export const IconTradeIn = /* #__PURE__ */ createIconWithPath(TradeIn);
export const IconTrendingDown = /* #__PURE__ */ createIconWithPath(
  TrendingDown
);
export const IconTrendingFlat = /* #__PURE__ */ createIconWithPath(
  TrendingFlat
);
export const IconTrendingUp = /* #__PURE__ */ createIconWithPath(TrendingUp);
export const IconUndo = /* #__PURE__ */ createIconWithPath(Undo);
export const IconUnfoldLess = /* #__PURE__ */ createIconWithPath(UnfoldLess);
export const IconUnfoldMore = /* #__PURE__ */ createIconWithPath(UnfoldMore);
export const IconUser = /* #__PURE__ */ createIconWithPath(User);
export const IconUserAdd = /* #__PURE__ */ createIconWithPath(UserAdd);
export const IconUsers = /* #__PURE__ */ createIconWithPath(Users);
export const IconValet = /* #__PURE__ */ createIconWithPath(Valet);
export const IconVehicleBus = /* #__PURE__ */ createIconWithPath(VehicleBus);
export const IconVehicleCar = /* #__PURE__ */ createIconWithPath(VehicleCar);
export const IconVehicleLeaving = /* #__PURE__ */ createIconWithPath(
  VehicleLeaving
);
export const IconVehicleLoaner = /* #__PURE__ */ createIconWithPath(
  VehicleLoaner
);
export const IconVehicleShuttle = /* #__PURE__ */ createIconWithPath(
  VehicleShuttle
);
export const IconViewCard = /* #__PURE__ */ createIconWithPath(ViewCard);
export const IconViewGrid = /* #__PURE__ */ createIconWithPath(ViewGrid);
export const IconViewList = /* #__PURE__ */ createIconWithPath(ViewList);
export const IconVisibility = /* #__PURE__ */ createIconWithPath(Visibility);
export const IconVisibilityOff = /* #__PURE__ */ createIconWithPath(
  VisibilityOff
);
export const IconVoidRequest = /* #__PURE__ */ createIconWithPath(VoidRequest);
export const IconWaiting = /* #__PURE__ */ createIconWithPath(Waiting);
export const IconWaitingOnCustomerResponse = /* #__PURE__ */ createIconWithPath(
  WaitingOnCustomerResponse
);
export const IconWaitingParts = /* #__PURE__ */ createIconWithPath(
  WaitingParts
);
export const IconWaitingWarranty = /* #__PURE__ */ createIconWithPath(
  WaitingWarranty
);
export const IconWarranty = /* #__PURE__ */ createIconWithPath(Warranty);
export const IconWeightScale = /* #__PURE__ */ createIconWithPath(WeightScale);
export const IconZoomIn = /* #__PURE__ */ createIconWithPath(ZoomIn);
export const IconZoomOut = /* #__PURE__ */ createIconWithPath(ZoomOut);
