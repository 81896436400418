import { css } from 'styled-components';

export const applyStateStyles = ({
  isDisabled = false,
  stateStyles,
  styledSelector = '&',
  useActiveStyles = true,
  useDefaultStyles = true,
  useFocusStyles = true,
  useHoverStyles = true,
}) => css`
  &:focus {
    outline: none;
  }

  ${useDefaultStyles && stateStyles?.default};
  ${isDisabled && stateStyles?.disabled}
  ${!isDisabled &&
  useHoverStyles &&
  css`
    ${styledSelector}:hover {
      ${stateStyles.hover};
    }
  `}
    ${!isDisabled &&
  useFocusStyles &&
  css`
    ${styledSelector}:active {
      ${styledSelector}:focus {
        box-shadow: none;
      }
    }
  `}
    ${!isDisabled &&
  useActiveStyles &&
  css`
    ${styledSelector}:active {
      ${stateStyles.active};
    }
  `}
`;
