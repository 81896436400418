import React from 'react';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { transitionAnimation, getRem } from './../../core';

const StyledButtonIcon = styled(({ icon, ...props }) => React.cloneElement(icon, props)).withConfig({
  shouldForwardProp: (prop) => !['iconSize'].includes(prop),
})`
  ${transitionAnimation('fill')};
  display: inline-flex;
  flex-shrink: 0;
  z-index: ${({ theme }) => theme.zIndex.buttonContent.value};

  ${({ iconSize }) =>
    css`
      height: ${getRem(iconSize === ICON_SIZE.SMALL ? ICON_SIZE.SMALL : ICON_SIZE.DEFAULT)};
      width: ${getRem(iconSize === ICON_SIZE.SMALL ? ICON_SIZE.SMALL : ICON_SIZE.DEFAULT)};
    `}
`;

const ButtonIcon = ({ children, iconSize, ...other }) => (
  <StyledButtonIcon icon={children} iconSize={iconSize} {...other} />
);

ButtonIcon.propTypes = {
  /** Will render icon passed between component tags. Icon should be passed as a component */
  children: PropTypes.node,
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
};

ButtonIcon.defaultProps = {
  children: undefined,
  iconSize: ICON_SIZE.DEFAULT,
};

export { ButtonIcon };
