import React from 'react';

import { IconSearch } from 'lib/icons';
import { useShareForwardedRef, useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';

import { SearchInputClearButton, SearchInputInput } from '../blocks';
import { EXPAND_BEHAVIORS } from '../constants';
import { SearchInputContainer, SearchInputIcon, SearchInputIndicator } from '../elements';
import { isMobileScreen } from './../../core';
import { INPUT_ICON_POSITIONS, INPUT_SIZES, moveInputCursorToStart } from './../../input';

const SearchInput = React.forwardRef(
  (
    {
      clearButtonLabel,
      dataTestId,
      disableBrowserAutocomplete,
      expandBehavior,
      id,
      isAlwaysExpanded,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      size,
      value,
      ...other
    },
    ref
  ) => {
    const inputRef = useShareForwardedRef(ref);
    const windowSize = useWindowSize();

    const isFilled = !!value;
    const isMobileView = isMobileScreen(windowSize.width);

    const handleBlur = ({ target }) => {
      if (isMobileView) {
        onFocus(target.value);
      }

      moveInputCursorToStart(target, value);
      onBlur();
    };

    const handleChange = ({ target }) => {
      onChange(target.value);
    };

    const handleClear = () => {
      onChange('');
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleFocus = (event) => {
      onFocus(event);
      if (isMobileView) {
        onFocus(true);
      }
    };

    return (
      <SearchInputContainer
        data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}
        expandBehavior={expandBehavior}
        {...other}
      >
        <SearchInputInput
          autoComplete={disableBrowserAutocomplete ? 'off' : ''}
          data-testid={dataTestId}
          id={id}
          isAlwaysExpanded={isAlwaysExpanded}
          isFilled={isFilled}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          name={name}
          ref={inputRef}
          size={size}
          type="text"
          value={value}
        />
        <SearchInputIndicator expandBehavior={expandBehavior} size={size} />
        <SearchInputIcon
          expandBehavior={expandBehavior}
          icon={<IconSearch />}
          isAlwaysExpanded={isAlwaysExpanded}
          isFilled={isFilled}
          position={INPUT_ICON_POSITIONS.TRAILING}
        />
        <SearchInputClearButton
          data-testid={dataTestId ? `${dataTestId}-clear-input-button` : undefined}
          clearButtonLabel={clearButtonLabel}
          isVisible={isFilled}
          onClick={handleClear}
        />
      </SearchInputContainer>
    );
  }
);

SearchInput.propTypes = {
  /** Label of the clear input button */
  clearButtonLabel: PropTypes.node,
  /** Allows disabling of browser-based Autocomplete (true == disabled) */
  disableBrowserAutocomplete: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Defines Search Input expanding behaviour */
  expandBehavior: PropTypes.oneOf(Object.values(EXPAND_BEHAVIORS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** If true, search input is always expanded */
  isAlwaysExpanded: PropTypes.bool,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input is out of focus */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Callback to be called when input is focused */
  onFocus: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Current value of the input field */
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  clearButtonLabel: 'Clear search input',
  dataTestId: undefined,
  expandBehavior: EXPAND_BEHAVIORS.SLIDE_RIGHT,
  isAlwaysExpanded: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: '',
  size: INPUT_SIZES.STANDARD,
  value: '',
};

export { SearchInput };
