import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LOADER_VARIANTS } from '../constants';
import { StyledLoaderSpinner, LoaderLabel } from '../elements';

const StyledLoaderWrapper = styled.div`
  align-items: center;
  cursor: progress;
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: center;
  stroke: ${({ theme }) => theme.color.additional.black.value};
`;

export const Loader = ({ hideLabel, label, spinnerClassName, variant, ...other }) => (
  <StyledLoaderWrapper role="status" {...other}>
    <StyledLoaderSpinner className={spinnerClassName} />
    {label && (
      <LoaderLabel hideLabel={hideLabel} label={label}>
        {label}
      </LoaderLabel>
    )}
  </StyledLoaderWrapper>
);

Loader.propTypes = {
  /** Visually hides loader text */
  hideLabel: PropTypes.bool,
  /** Adds message on the right of the spinner */
  label: PropTypes.node.isRequired,
  /** Adds additional class to spinner */
  spinnerClassName: PropTypes.string,
  /** Loader variations. Values: ['inline'] */
  variant: PropTypes.oneOf(Object.values(LOADER_VARIANTS)),
};

Loader.defaultProps = {
  hideLabel: false,
  spinnerClassName: '',
  variant: LOADER_VARIANTS.INLINE,
};
