import React from 'react';
import PropTypes from 'prop-types';

import { ICON_SIZE } from './constants';

const createIconWithPath = (paths) => {
  const CreateIconWithPath = React.forwardRef(
    ({ ariaLabel, className, focusable, height, role, viewBox, width, ...other }, ref) => {
      const ariaAttrs = {};

      if (ariaLabel) {
        ariaAttrs['aria-label'] = ariaLabel;
      }

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          tabIndex={focusable ? 0 : undefined}
          focusable={focusable}
          height={height}
          ref={ref}
          role={role}
          viewBox={viewBox}
          width={width}
          {...ariaAttrs}
          {...other}
        >
          {paths.map((path) => (
            <path key={path.d} {...path} />
          ))}
        </svg>
      );
    }
  );

  CreateIconWithPath.propTypes = {
    /** Adds aria label for icon */
    ariaLabel: PropTypes.node,
    /** Adds classname to the icon */
    className: PropTypes.string,
    /** Sets if icon is focusable when using tab */
    focusable: PropTypes.bool,
    /** Sets the height of the icon */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Sets the role of the icon */
    role: PropTypes.string,
    /** Sets icon viewbox */
    viewBox: PropTypes.string,
    /** Sets the width of the icon */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  CreateIconWithPath.defaultProps = {
    ariaLabel: '',
    className: '',
    focusable: false,
    height: ICON_SIZE.DEFAULT,
    role: 'presentation',
    viewBox: '0 0 24 24',
    width: ICON_SIZE.DEFAULT,
  };

  return CreateIconWithPath;
};

export { createIconWithPath };
