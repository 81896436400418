import React from 'react';
import PropTypes from 'prop-types';

import { CardWrapper } from '../../elements/CardWrapper';

const Card = ({ customTag, draggable, ...other }) => (
  <CardWrapper customTag={customTag} draggable={draggable} {...other} />
);

Card.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
  /** If true, makes component draggable */
  draggable: PropTypes.bool,
};

Card.defaultProps = {
  customTag: undefined,
  draggable: false,
};

export { Card };
