import { useEffect, useRef } from 'react';

const useShareForwardedRef = (forwardedRef) => {
  // Ref that will share value with forward ref. This is the one we will attach to components
  const innerRef = useRef();

  useEffect(() => {
    // after every render - try to share current ref value with forwarded ref
    if (!forwardedRef) {
      return;
    }

    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
      return;
    }

    // eslint-disable-next-line no-param-reassign
    forwardedRef.current = innerRef.current;
  });

  return innerRef;
};

export { useShareForwardedRef };
