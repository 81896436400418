export const LOADER_VARIANTS = {
  INLINE: 'inline',
  INDETERMINATE: 'indeterminate',
  DETERMINATE: 'determinate',
};

export const LOADER_LABEL_VARIANTS = {
  INDETERMINATE: 'indeterminate',
  DETERMINATE: 'determinate',
};

export const LOADER_SIZE = {
  LARGE: 'large',
  SMALL: 'small',
};
