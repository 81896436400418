import { css, keyframes } from 'styled-components';

export const transitionAnimation = (transitionProperties) => css`
  transition-duration: ${({ theme }) => theme.transition.duration.value};
  transition-property: ${transitionProperties};
  transition-timing-function: ${({ theme }) => theme.transition.timing.value};
`;

export const keyframesAnimation = (keyframes) => css`
  animation-duration: ${({ theme }) => theme.transition.duration.value};
  animation-name: ${keyframes};
  animation-timing-function: ${({ theme }) => theme.transition.timing.value};
`;

export const rotateAnimation = css`
  @keyframes rotateAnimation {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const rotateHalfwayAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
`;

export const dashAnimation = css`
  @keyframes dashAnimation {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }

    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }
`;
