import React from 'react';
import { getHexToRgb, typographyButton, typographyGroupLabel, visuallyHidden } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { LOADER_SIZE, LOADER_LABEL_VARIANTS } from '../constants';

export const StyledLabel = styled.span`
  ${({ theme }) => typographyButton(theme)}
  color: inherit;
  line-height: 1;
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ hideLabel }) => hideLabel && visuallyHidden}
  ${({ isInline, labelMaxWidth, labelVariant, loaderSize, theme }) =>
    (labelVariant === LOADER_LABEL_VARIANTS.INDETERMINATE || labelVariant === LOADER_LABEL_VARIANTS.DETERMINATE) &&
    css`
      ${typographyGroupLabel(theme)};
      color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.7)`};
      font-size: ${loaderSize === LOADER_SIZE.SMALL ? theme.font.size.body1.value : theme.font.size.default.value};
      margin-left: ${({ theme }) => theme.size.spacing.medium.value};
      max-width: ${isInline ? '194px' : labelMaxWidth};
      min-width: ${isInline ? 'inherit' : '100px'};
      overflow-wrap: break-word;
      padding-top: 1px;
      word-wrap: break-word;
    `};
`;
export const LoaderLabel = ({
  className,
  dataTestId,
  hideLabel,
  isInline,
  label,
  labelMaxWidth,
  labelVariant,
  loaderSize,
  ...other
}) => {
  return label ? (
    <StyledLabel
      className={className}
      data-testid={dataTestId ? `${dataTestId}-label` : undefined}
      hideLabel={hideLabel}
      isInline={isInline}
      labelMaxWidth={labelMaxWidth}
      loaderSize={loaderSize}
      labelVariant={labelVariant}
      {...other}
    >
      {label}
    </StyledLabel>
  ) : null;
};

LoaderLabel.propTypes = {
  /** Adds additional class name to the label for styling */
  className: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, label will set a max-width before text will wrap on the word. */
  isInline: PropTypes.bool,
  /** Visually hides loader text */
  hideLabel: PropTypes.bool,
  /** Adds label text  */
  label: PropTypes.node,
  /** Determines the max-width of the label  */
  labelMaxWidth: PropTypes.string,
  /** Sets the size of the loader to small or large */
  loaderSize: PropTypes.oneOf(Object.values(LOADER_SIZE)),
  /**  The type of loader label */
  labelVariant: PropTypes.oneOf(Object.values(LOADER_LABEL_VARIANTS)),
};

LoaderLabel.defaultProps = {
  className: '',
  dataTestId: '',
  isInline: false,
  hideLabel: false,
  label: 'loading...',
  labelMaxWidth: '500px',
  loaderSize: LOADER_SIZE.LARGE,
  labelVariant: undefined,
};
