import { DEFAULT_FONT_SIZE_IN_PIXELS } from '../constants';

const getUnitlessLineHeight = (lineHeight, fontSize) => Math.round((lineHeight / fontSize) * 100) / 100;

export const getLineHeight = (lineHeight, fontSize) => {
  const parsedFontSize = parseFloat(fontSize);
  const parsedLineHeight = parseFloat(lineHeight);

  if (fontSize.includes('rem')) {
    const fontSizeInPixels = parsedFontSize * DEFAULT_FONT_SIZE_IN_PIXELS;

    return getUnitlessLineHeight(parsedLineHeight, fontSizeInPixels);
  }

  return getUnitlessLineHeight(parsedLineHeight, parsedFontSize);
};
