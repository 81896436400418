import styled from 'styled-components';

import { EXPAND_BEHAVIORS } from '../constants';

const SearchInputContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['expandBehavior'].includes(prop),
})`
  align-items: center;
  display: flex;
  justify-content: ${({ expandBehavior }) =>
    expandBehavior === EXPAND_BEHAVIORS.SLIDE_LEFT ? 'flex-end' : 'flex-start'};
  position: relative;
  width: 100%;
`;

export { SearchInputContainer };
