import React from 'react';
import { IconCancelOutlined } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, transitionAnimation, visuallyHidden } from './../../core';

const StyledSearchInputClearButtonIcon = styled(IconCancelOutlined)`
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${getRem('24px')};
  width: ${getRem('24px')};
`;

const StyledSearchInputClearButtonLabel = styled.span`
  ${visuallyHidden};
`;

const StyledSearchInputClearButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})`
  ${transitionAnimation('background-color, fill')}
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  cursor: pointer;
  display: flex;
  height: ${getRem('24px')};
  justify-content: center;
  padding: 0;
  position: absolute;
  right: ${({ theme }) => theme.size.spacing.medium.value};
  top: 50%;
  transform: translateY(-50%);
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: ${getRem('24px')};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.text.light.secondary.value};

    ${StyledSearchInputClearButtonIcon} {
      fill: ${({ theme }) => theme.color.additional.light.value};
    }
  }
`;

const SearchInputClearButton = ({ clearButtonLabel, isVisible, onClick, ...other }) => {
  return (
    <StyledSearchInputClearButton
      isVisible={isVisible}
      onClick={onClick}
      tabIndex={isVisible ? 0 : -1}
      type="button"
      {...other}
    >
      <StyledSearchInputClearButtonLabel>{clearButtonLabel}</StyledSearchInputClearButtonLabel>
      {isVisible && <StyledSearchInputClearButtonIcon />}
    </StyledSearchInputClearButton>
  );
};

SearchInputClearButton.propTypes = {
  /** Label of the clear input button */
  clearButtonLabel: PropTypes.node,
  /** Specifies whether to display or visually hide the clear input button */
  isVisible: PropTypes.bool,
  /** Callback called on Clear Button click */
  onClick: PropTypes.func.isRequired,
};

SearchInputClearButton.defaultProps = {
  clearButtonLabel: 'Clear search input',
  isVisible: true,
};

export { SearchInputClearButton };
