import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { KEY_CODES, useShareForwardedRef } from './../../../utilities';

import { applyStateStyles } from './applyStateStyles';

const StyledElementStateWrapper = styled(({ children, ...props }) => React.cloneElement(children, props)).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'isActive',
      'isDisabled',
      'shouldPreventDefault',
      'showOutline',
      'stateStyles',
      'styledSelector',
      'useActiveStyles',
      'useDefaultStyles',
      'useFocusStyles',
      'useHoverStyles',
    ].includes(prop),
})`
  && {
    outline: none;
    ${({ isDisabled, stateStyles, useActiveStyles, useDefaultStyles, useHoverStyles }) =>
      css`
        ${applyStateStyles({
          isDisabled,
          stateStyles,
          useActiveStyles,
          useDefaultStyles,
          useHoverStyles,
        })}
      `}

    ${({ isActive, isDisabled, showOutline, stateStyles, useFocusStyles }) =>
      !isDisabled &&
      css`
        &:focus {
          outline: none;
          ${!isActive &&
          showOutline &&
          useFocusStyles &&
          css`
            ${stateStyles.focus};
          `};
        }

        ${isActive &&
        css`
          ${stateStyles.active};
        `};
      `}
  }
`;

const ElementStateWrapper = ({ children, onKeyDown, triggerClickEventAsKeyDown, triggerKeys, ...other }) => {
  const defaultRef = useRef(null);
  const ref = useShareForwardedRef(children.ref || defaultRef);

  const { useFocusStyles: isFocusable } = other;
  const [isActive, setIsActive] = useState(false);
  const [showOutline, setShowOutline] = useState(false);

  const shouldTriggerAction = (event) => {
    const eventKeyCode = event.keyCode || event.which || 0;

    return isFocusable && triggerClickEventAsKeyDown && triggerKeys.includes(eventKeyCode);
  };

  const elementFocusProps = isFocusable ? { tabIndex: -1 } : {};

  const handleKeyDown = (event) => {
    if (shouldTriggerAction(event)) {
      setShowOutline(false);
    }

    onKeyDown(event);
  };

  const handleKeyUp = (event) => {
    if (shouldTriggerAction(event)) {
      const hasRef = ref && ref.current;

      if (hasRef) {
        setIsActive(true);
        ref.current.click();
      }
    }

    setIsActive(false);
    setShowOutline(true);
  };

  const handleMouseDown = () => {
    setShowOutline(false);
  };

  return (
    <StyledElementStateWrapper
      isActive={isActive}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseDown={handleMouseDown}
      showOutline={showOutline}
      tabIndex={isFocusable ? 0 : -1}
      {...other}
    >
      {React.cloneElement(children, { ref, ...elementFocusProps })}
    </StyledElementStateWrapper>
  );
};

ElementStateWrapper.propTypes = {
  /** Content inserted between component tags. Content must be wrapped in a single element. */
  children: PropTypes.node.isRequired,
  /** Uses disabled styling if such exists and prevents other state styles from being applied */
  isDisabled: PropTypes.bool,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true uses event.preventDefault and event.stopPropagation after triggerKeys are pressed  */
  shouldPreventDefault: PropTypes.bool,
  /** Element state styles applied on user interaction */
  stateStyles: PropTypes.shape({
    /** Specifies default styling applied to the element */
    default: PropTypes.array,
    /** Specifies disabled state styles applied when element is disabled */
    disabled: PropTypes.array,
    /** Specifies focus state styles applied when element is focused */
    focus: PropTypes.array,
    /** Specifies hover state styles applied when element is hovered */
    hover: PropTypes.array,
  }).isRequired,
  /** Specifies to which component should the styles be applied to; Requires a Styled Component */
  styledSelector: PropTypes.elementType,
  /** If true, triggers onClick callback if key matches triggerKeys values */
  triggerClickEventAsKeyDown: PropTypes.bool,
  /** Specifies keys which trigger element click event */
  triggerKeys: PropTypes.arrayOf(PropTypes.number),
  /** Specifies whether active styles should be used */
  useActiveStyles: PropTypes.bool,
  /** Specifies whether default styles should be used */
  useDefaultStyles: PropTypes.bool,
  /** Specifies whether focus styles should be used */
  useFocusStyles: PropTypes.bool,
  /** Specifies whether hover styles should be used */
  useHoverStyles: PropTypes.bool,
};

ElementStateWrapper.defaultProps = {
  isDisabled: false,
  onKeyDown: () => {},
  shouldPreventDefault: false,
  styledSelector: undefined,
  triggerClickEventAsKeyDown: false,
  triggerKeys: [KEY_CODES.ENTER, KEY_CODES.SPACE],
  useActiveStyles: true,
  useDefaultStyles: true,
  useFocusStyles: true,
  useHoverStyles: true,
};

export { ElementStateWrapper, StyledElementStateWrapper };
