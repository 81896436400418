export const BUTTON_BACKGROUND_APPEARANCES = {
  DARK: 'dark',
  LIGHT: 'light',
  PRIMARY: 'primary',
};

export const BUTTON_VARIANTS = {
  ICON: 'icon',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
};

export const BUTTON_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};

export const BUTTON_ICON_COLORS = {
  GRAY: 'gray',
  PRIMARY: 'primary',
};
